import { DEFAULT_LAYOUT } from '~/router/constants'
import type { AppRouteRecordRaw } from '../types'

const INDUSTRY_SELF_CHECK_GUIDE: AppRouteRecordRaw = {
  path: '/industry_self_check_guide',
  name: 'IndustrySelfCheckGuide',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'TQC导则',
    requiresAuth: true,
    icon: 'icon-bookmark',
    order: 5,
  },
  children: [
    {
      path: 'oil_tanker',
      name: 'OilTanker',
      component: () => import('~/views/industry_self_check_guide/oil_tanker/index.vue'),
      meta: {
        locale: '油船检查导则',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
        ],
      },
    },
    {
      path: 'chemical_tanker',
      name: 'ChemicalTanker',
      component: () => import('~/views/industry_self_check_guide/chemical_tanker/index.vue'),
      meta: {
        locale: '化学品船检查导则',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
        ],
      },
    },
    {
      path: 'bulk_carrier',
      name: 'BulkCarrier',
      component: () => import('~/views/industry_self_check_guide/bulk_carrier/index.vue'),
      meta: {
        locale: '散货船检查导则',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
        ],
      },
    },
  ],
}

export default INDUSTRY_SELF_CHECK_GUIDE
