import { DEFAULT_LAYOUT } from '~/router/constants'
import type { AppRouteRecordRaw } from '../types'

const DASHBOARD: AppRouteRecordRaw = {
  path: '/single',
  name: 'SingleDashboard',
  component: DEFAULT_LAYOUT,
  redirect: '/dashboard',
  meta: {
    locale: '智慧数据窗口',
    requiresAuth: true,
    icon: 'icon-computer',
    order: 7,
    roles: [
      ROLE_NAME.超级管理员, // 0
      ROLE_NAME.码头管理员, // 2
      ROLE_NAME.码头账号, // 2
      ROLE_NAME.海事管理员, // 3
      ROLE_NAME.海事账号, // 3
    ],
  },
}
export default DASHBOARD
