import { DEFAULT_LAYOUT } from '~/router/constants'
import type { AppRouteRecordRaw } from '../types'

const WARNING_REMINDER: AppRouteRecordRaw = {
  path: '/warning_reminder',
  name: 'WarningReminder',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '预警提醒',
    requiresAuth: true,
    icon: 'icon-exclamation-circle',
    order: 3,
  },
  children: [
    {
      path: 'important_ship',
      name: 'ImportantShip',
      component: () => import('~/views/warning_reminder/important_ship/index.vue'),
      meta: {
        locale: '重点船舶提醒',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
    },

    {
      path: 'announcement',
      name: 'Announcement',
      component: () => import('~/views/warning_reminder/announcement/index.vue'),
      meta: {
        locale: '公告信息提醒',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
    },
    {
      path: 'inspection_due_date',
      name: 'InspectionDueDate',
      component: () => import('~/views/warning_reminder/inspection_due_date/index.vue'),
      meta: {
        locale: '检查到期提醒',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
        ],
      },
    },
  ],
}

export default WARNING_REMINDER
