import { DEFAULT_LAYOUT } from '~/router/constants'
import type { AppRouteRecordRaw } from '../types'

const ACCOUNT: AppRouteRecordRaw = {
  path: '/account',
  name: 'Account',
  component: DEFAULT_LAYOUT,
  redirect: '/account/center',
  meta: {
    locale: '基础设置',
    requiresAuth: true,
    icon: 'icon-user',
    order: 9,
  },
  children: [
    {
      path: 'center',
      name: 'AccountCenter',
      component: () => import('~/views/account/center/index.vue'),
      meta: {
        locale: '账号详情',
        requiresAuth: true,
        roles: [
          '*',
        ],
      },
    },
    {
      path: 'user',
      name: 'AccountUser',
      component: () => import('~/views/account/user/index.vue'),
      meta: {
        locale: '用户管理',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.海事管理员, // 3
        ],
      },
    },
    {
      path: 'role',
      name: 'AccountRole',
      component: () => import('~/views/account/role/index.vue'),
      meta: {
        locale: '角色管理',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
        ],
      },
    },
    {
      path: 'dict',
      name: 'AccountDict',
      redirect: '/account/dict/page',
      meta: {
        locale: '字典管理',
        requiresAuth: true,
        hideInMenu: false,
        roles: [
          ROLE_NAME.超级管理员, // 0
        ],
      },
      children: [
        {
          path: 'page',
          name: 'AccountDictPage',
          component: () => import('~/views/account/dict/index.vue'),
          meta: {
            locale: '字典列表',
            activeMenu: 'AccountDict',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
            ],
          },
        },
        {
          path: 'item',
          name: 'AccountDictItem',
          component: () => import('~/views/account/dict/item.vue'),
          meta: {
            locale: '字典项列表',
            activeMenu: 'AccountDict',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
            ],
          },
        },
      ],
    },

    // {
    //   path: 'dashboard',
    //   name: 'AccountDashboard',
    //   redirect: '/dashboard',
    //   meta: {
    //     locale: '智慧数据窗口',
    //     requiresAuth: true,
    //     roles: [
    //       '*',
    //     ],
    //   },
    // },
  ],
}

export default ACCOUNT
