import { DEFAULT_LAYOUT } from '~/router/constants'
import type { AppRouteRecordRaw } from '../types'

const LAW_RULE: AppRouteRecordRaw = {
  path: '/law_rule',
  name: 'LawRule',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '法律/规则',
    requiresAuth: true,
    icon: 'icon-book',
    order: 4,
  },
  children: [
    {
      path: 'maritime_law_regulations',
      name: 'MaritimeLawRegulations',
      component: () => import('~/views/law_rule/maritime_law_regulations/index.vue'),
      meta: {
        locale: '海事法律法规',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
    },
    {
      path: 'port_captain_rules',
      name: 'PortCaptainRules',
      component: () => import('~/views/law_rule/port_captain_rules/index.vue'),
      meta: {
        locale: '码头船长规则',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
    },
  ],
}

export default LAW_RULE
