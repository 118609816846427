import { ROLE_NAME } from '~/composables/constant'
import { DEFAULT_LAYOUT } from '~/router/constants'
import type { AppRouteRecordRaw } from '../types'

const PRICE: AppRouteRecordRaw = {
  path: '/price',
  name: 'Price',
  component: DEFAULT_LAYOUT,
  redirect: '/price/center',
  meta: {
    locale: '财务管理',
    requiresAuth: true,
    icon: 'icon-wechatpay',
    order: 10,
  },
  children: [
    {
      path: 'center',
      name: 'PriceCenter',
      component: () => import('~/views/price/center/index.vue'),
      meta: {
        locale: '定价中心',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员,
          ROLE_NAME.财务账号,
        ],
      },
    },
    {
      path: 'shipping',
      name: 'PriceShipping',
      redirect: '/price/shipping/page',
      meta: {
        locale: '航运定价',
        requiresAuth: true,
        hideInMenu: false,
        roles: [
          ROLE_NAME.超级管理员,
          ROLE_NAME.财务账号,
        ],
      },
      children: [
        {
          path: 'page',
          name: 'PriceShippingPage',
          component: () => import('~/views/price/shipping/index.vue'),
          meta: {
            locale: '定价记录',
            activeMenu: 'PriceShipping',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员,
              ROLE_NAME.财务账号,
            ],
          },
        },
        {
          path: 'modify',
          name: 'PriceShippingModify',
          component: () => import('~/views/price/shipping/modify.vue'),
          meta: {
            locale: '定价修改',
            activeMenu: 'PriceShipping',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员,
              ROLE_NAME.财务账号,
            ],
          },
        },
      ],
    },
    {
      path: 'info',
      name: 'PriceInfo',
      component: () => import('~/views/price/info/index.vue'),
      meta: {
        locale: '定价说明',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员,
        ],
      },
    },

    {
      path: 'invoice',
      name: 'PriceInvoice',
      component: () => import('~/views/price/invoice/index.vue'),
      meta: {
        locale: '开票申请',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员,
          ROLE_NAME.财务账号,
          ROLE_NAME.航运账号,
          ROLE_NAME.航运管理员,
        ],
      },
    },
  ],
}

export default PRICE
