import axios from 'axios'
import type { AxiosRequestConfig } from 'axios'
import { errorInterceptor } from './errorInterceptors'
import { requestInterceptor } from './requestInterceptors'
import { responseInterceptor } from './responseInterceptors'

const baseURL = import.meta.env.NODE_ENV === 'test' ? import.meta.env.VITE_PROXY_URL : BASE_URL

const commonConfig: AxiosRequestConfig = {
  baseURL,
  validateStatus: (status: number) => {
    return status >= 200 && status <= 600 // 全部允许, 不会遇到错误就停止
  },
}

export const requestWithAdmin = axios.create(commonConfig)

requestWithAdmin.interceptors.request.use(requestInterceptor, errorInterceptor)
requestWithAdmin.interceptors.response.use(responseInterceptor, errorInterceptor)
