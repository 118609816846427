import NProgress from 'nprogress'

import type { LocationQueryRaw, Router } from 'vue-router'

import { useUserStore } from '~/store'
import { queryAll } from '~/store/modules/global'
import { isLogin } from '~/utils/auth'
// progress bar
import { NO_LOGIN } from '../constants'

export default function setupUserLoginInfoGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    NProgress.start()
    const userStore = useUserStore()
    // console.log(isLogin())
    if (isLogin()) {
      // console.log(userStore.roles.length)
      // console.log(to.name)
      if (to.name === loginRouteName) {
        next({
          name: defaultRouteName,
        })
      }
      else {
        // console.log(userStore.roles)
        if (userStore.roles.length) {
          next()
        }
        else {
          try {
            await userStore.info()
            await queryAll()
            next()
          }
          catch (error) {
            console.error(error)
            next({
              name: loginRouteName,
              // query: {
              //   redirect: to.name,
              // } as LocationQueryRaw,
            })
          }
        }
      }
    }
    else {
      if (NO_LOGIN.includes(to.name as string)) {
        next()
      }
      else {
        next({
          name: loginRouteName,
          query: {
            redirect: to.name,
          } as LocationQueryRaw,
        })
      }
    }
  })
}
