import { DEFAULT_LAYOUT } from '~/router/constants'
import type { AppRouteRecordRaw } from '../types'

const LIBRARY: AppRouteRecordRaw = {
  path: '/library',
  name: 'Library',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '库管理',
    requiresAuth: true,
    icon: 'icon-storage',
    order: 6,
  },
  children: [
    {
      path: 'vqc_ship',
      name: 'VqcShip',
      redirect: '/library/vqc_ship/page',
      meta: {
        locale: 'VQC船舶库',
        requiresAuth: true,
        hideInMenu: false,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
          ROLE_NAME.专家账号, // 4
          ROLE_NAME.货主账号, // 5
        ],
      },
      children: [
        {
          path: 'page',
          name: 'VqcShipPage',
          component: () => import('~/views/library/vqc_ship/index.vue'),
          meta: {
            locale: '船舶列表',
            activeMenu: 'VqcShip',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
              ROLE_NAME.海事管理员, // 3
              ROLE_NAME.海事账号, // 3
              ROLE_NAME.专家账号, // 4
              ROLE_NAME.货主账号, // 5
            ],
          },
        },
        {
          path: 'new',
          name: 'VqcShipNew',
          component: () => import('~/views/library/vqc_ship/new.vue'),
          meta: {
            locale: '新增船舶',
            activeMenu: 'VqcShip',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
            ],
          },
        },
        {
          path: 'edit',
          name: 'VqcShipEdit',
          component: () => import('~/views/library/vqc_ship/edit.vue'),
          meta: {
            locale: '编辑船舶',
            activeMenu: 'VqcShip',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
            ],
          },
        },
      ],
    },
    {
      path: 'shipping_company',
      name: 'ShippingCompany',
      component: () => import('~/views/library/shipping_company/index.vue'),
      meta: {
        locale: '航运企业库',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
          ROLE_NAME.专家账号, // 4
          ROLE_NAME.货主账号, // 5
        ],
      },
    },
    {
      path: 'port_company',
      name: 'PortCompany',
      component: () => import('~/views/library/port_company/index.vue'),
      meta: {
        locale: '码头企业库',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
          ROLE_NAME.专家账号, // 4
          ROLE_NAME.货主账号, // 5
        ],
      },
    },
    {
      path: 'maritime_company',
      name: 'MaritimeCompany',
      component: () => import('~/views/library/maritime_company/index.vue'),
      meta: {
        locale: '海事企业库',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
        ],
      },
    },
    {
      path: 'expert',
      name: 'Expert',
      component: () => import('~/views/library/expert/index.vue'),
      meta: {
        locale: '专家库',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
    },
    {
      path: 'oil',
      name: 'Oil',
      component: () => import('~/views/library/oil/index.vue'),
      meta: {
        locale: '油品库',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
        ],
      },
    },
    {
      path: 'tank',
      name: 'Tank',
      component: () => import('~/views/library/tank/index.vue'),
      meta: {
        locale: '作业罐号库',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
        ],
      },
    },
  ],
}

export default LIBRARY
