import { DEFAULT_LAYOUT, PAGE_LAYOUT } from '~/router/constants'
import type { AppRouteRecordRaw } from '../types'

const SHIP_RATING: AppRouteRecordRaw = {
  path: '/ship_rating',
  name: 'ShipRating',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '船舶评级',
    requiresAuth: true,
    icon: 'icon-select-all',
    order: 1,
  },
  children: [
    {
      path: 'ship_ratings',
      name: 'ShipRatings',
      component: PAGE_LAYOUT,
      meta: {
        locale: '船舶状况/海事监管',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.海事管理员, // 3
          ROLE_NAME.海事账号, // 3
        ],
      },
      children: [
        {
          path: 'ship_self_eval',
          name: 'ShipSelfEvil',
          component: () => import('~/views/ship_rating/ship_ratings/ship_self_eval/index.vue'),
          meta: {
            locale: '船方自评发起',
            requiresAuth: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
            ],
          },
        },
        {
          path: 'ship_self_inspect',
          name: 'ShipSelfInspect',
          component: () => import('~/views/ship_rating/ship_ratings/ship_self_inspect/index.vue'),
          meta: {
            locale: '船方自评检查',
            requiresAuth: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
            ],
          },
        },
        {
          path: 'inspect_rectify',
          name: 'InspectRectify',
          component: () => import('~/views/ship_rating/ship_ratings/inspect_rectify/index.vue'),
          meta: {
            locale: '自评检查整改',
            requiresAuth: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
              ROLE_NAME.海事管理员, // 3
              ROLE_NAME.海事账号, // 3
            ],
          },
        },
        {
          path: 'self_new',
          name: 'SelfNew',
          component: () => import('~/views/ship_rating/ship_ratings/self_new/index.vue'),
          meta: {
            locale: '自评发起',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
            ],
          },
        },
        {
          path: 'self_edit',
          name: 'SelfEdit',
          component: () => import('~/views/ship_rating/ship_ratings/self_edit/index.vue'),
          meta: {
            locale: '自评编辑',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
            ],
          },
        },
        {
          path: 'self_review',
          name: 'SelfReview',
          component: () => import('~/views/ship_rating/ship_ratings/self_review/index.vue'),
          meta: {
            locale: '自评审核',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
            ],
          },
        },
        {
          path: 'inspect_init',
          name: 'Inspect_init',
          component: () => import('~/views/ship_rating/ship_ratings/inspect_init/index.vue'),
          meta: {
            locale: '检查发起',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
              ROLE_NAME.海事管理员, // 3
              ROLE_NAME.海事账号, // 3
            ],
          },
        },
        {
          path: 'inspect_review',
          name: 'InspectReview',
          component: () => import('~/views/ship_rating/ship_ratings/inspect_review/index.vue'),
          meta: {
            locale: '查看检查',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
              ROLE_NAME.海事管理员, // 3
              ROLE_NAME.海事账号, // 3
            ],
          },
        },
      ],
    },

    {
      path: 'industry_self_regulation_inspection',
      name: 'IndustrySelfRegulationInspection',
      meta: {
        locale: '行业自律检查',
        requiresAuth: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.专家账号, // 4
        ],
      },
      children: [
        {
          path: 'app_init',
          name: 'AppInit',
          component: () => import('~/views/ship_rating/industry_self_regulation_inspection/app_init/index.vue'),
          meta: {
            locale: '申请发起',
            requiresAuth: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
            ],
          },
        },
        {
          path: 'app_review',
          name: 'AppReview',
          component: () => import('~/views/ship_rating/industry_self_regulation_inspection/app_review/index.vue'),
          meta: {
            locale: '审核申请',
            requiresAuth: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
            ],
          },
        },
        {
          path: 'inspect_init',
          name: 'InspectInit',
          component: () => import('~/views/ship_rating/industry_self_regulation_inspection/inspect_init/index.vue'),
          meta: {
            locale: '检查发起',
            requiresAuth: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
              ROLE_NAME.专家账号, // 4
            ],
          },
        },
      ],
    },
    {
      path: 'port_captain_inspection',
      name: 'PortCaptainInspection',
      redirect: '/ship_rating/port_captain_inspection/common',
      meta: {
        locale: '码头船长检查',
        requiresAuth: true,
        hideInMenu: false,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
        ],
      },
      children: [
        {
          path: 'common',
          name: 'PortCaptainInspectionCommon',
          component: () => import('~/views/ship_rating/port_captain_inspection/common.vue'),
          meta: {
            locale: '码头船长检查',
            activeMenu: 'PortCaptainInspection',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
            ],
          },
        },
        {
          path: 'zhy',
          name: 'PortCaptainInspectionZHY',
          component: () => import('~/views/ship_rating/port_captain_inspection/zhy.vue'),
          meta: {
            locale: '码头船长检查(企业版)',
            activeMenu: 'PortCaptainInspection',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
            ],
          },
        },
        {
          path: 'slh',
          name: 'PortCaptainInspectionSLH',
          component: () => import('~/views/ship_rating/port_captain_inspection/slh.vue'),
          meta: {
            locale: '码头船长检查(企业版)',
            activeMenu: 'PortCaptainInspection',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
            ],
          },
        },
      ],
    },

    {
      path: 'port_captain_inspection_shipping',
      name: 'PortCaptainInspectionShipping',
      component: () => import('~/views/ship_rating/port_captain_inspection/shipping.vue'),
      meta: {
        locale: '码头船长检查',
        requiresAuth: true,
        hideInMenu: false,
        roles: [
          // ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
        ],
      },
    },

    {
      path: 'regulation_checklist',
      name: 'RegulationChecklist',
      redirect: '/ship_rating/regulation_checklist/common',
      meta: {
        locale: '检查表单',
        requiresAuth: true,
        hideInMenu: true,
        roles: [
          // ROLE_NAME.超级管理员, // 0
          // ROLE_NAME.码头管理员, // 2
          // ROLE_NAME.码头账号, // 2
          // ROLE_NAME.专家账号, // 4
        ],
      },
      children: [
        {
          path: 'common',
          name: 'RegulationChecklistCommon',
          component: () => import('~/views/ship_rating/regulation_checklist/common.vue'),
          meta: {
            locale: '检查表单',
            activeMenu: 'PortCaptainInspection',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
              ROLE_NAME.专家账号, // 4
            ],
          },
        },
        {
          path: 'zhy',
          name: 'RegulationChecklistZHY',
          component: () => import('~/views/ship_rating/regulation_checklist/zhy.vue'),
          meta: {
            locale: '检查表单(企业版)',
            activeMenu: 'PortCaptainInspection',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
            ],
          },
        },
        {
          path: 'slh',
          name: 'RegulationChecklistSLH',
          component: () => import('~/views/ship_rating/regulation_checklist/slh.vue'),
          meta: {
            locale: '检查表单(企业版)',
            activeMenu: 'PortCaptainInspection',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
            ],
          },
        },
      ],
    },

    {
      path: 'regulation_checklist_result',
      name: 'RegulationChecklistResult',
      redirect: '/ship_rating/regulation_checklist_result/common',
      meta: {
        locale: '检查表单结果',
        requiresAuth: true,
        hideInMenu: true,
        roles: [
          ROLE_NAME.超级管理员, // 0
          ROLE_NAME.航运管理员, // 1
          ROLE_NAME.航运账号, // 1
          ROLE_NAME.码头管理员, // 2
          ROLE_NAME.码头账号, // 2
          ROLE_NAME.专家账号, // 4
        ],
      },
      children: [
        {
          path: 'common',
          name: 'RegulationChecklistResultCommon',
          component: () => import('~/views/ship_rating/regulation_checklist_result/common.vue'),
          meta: {
            locale: '检查表单结果',
            activeMenu: 'PortCaptainInspection',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
            ],
          },
        },
        {
          path: 'zhy',
          name: 'RegulationChecklistResultZHY',
          component: () => import('~/views/ship_rating/regulation_checklist_result/zhy.vue'),
          meta: {
            locale: '检查表单结果(企业版)',
            activeMenu: 'PortCaptainInspection',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
            ],
          },
        },
        {
          path: 'slh',
          name: 'RegulationChecklistResultSLH',
          component: () => import('~/views/ship_rating/regulation_checklist_result/slh.vue'),
          meta: {
            locale: '检查表单结果(企业版)',
            activeMenu: 'PortCaptainInspection',
            requiresAuth: true,
            hideInMenu: true,
            roles: [
              ROLE_NAME.超级管理员, // 0
              ROLE_NAME.航运管理员, // 1
              ROLE_NAME.航运账号, // 1
              ROLE_NAME.码头管理员, // 2
              ROLE_NAME.码头账号, // 2
            ],
          },
        },
      ],
    },
  ],
}

export default SHIP_RATING
